import { Api } from "../api/Api";
const state = {
  details: {}
};
const mutations = {
  UPDATE_PROFILE(state, payload) {
    state.details = payload;
  }
};

const actions = {
  async me({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/user/profile/me`);
      if (response) {
        commit("UPDATE_PROFILE", response.data);
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get fetch user profile details",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async updateProfile({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(`/user/profile/me`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `User profile updated successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get fetch user profile details",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async changePassword({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(`/user/change/password`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: "Password successfully changed",
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? "Unable to change password",
          status: "red"
        },
        { root: true }
      );
    }
  }
};

const getters = {
  getProfileDetails: state => state.details
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
