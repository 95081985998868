/* eslint-disable no-unused-vars */
import { Api } from "../api/Api";
import { from, range } from "rxjs";
import { map, reduce } from "rxjs/operators";
import Axios from "axios";
const state = {
  isPushNotificationEnabled: false,
  settings: null,
  notifications: [],
  currency: [],
  currencies: [],
  currentTheme: {},
  approvalSettings: {}
};

const mutations = {
  UPDATE_SETTINGS(state, payload) {
    state.settings = payload;
  },
  UPDATE_PUSH_NOTIFICATION_STATE(state, payload) {
    state.isPushNotificationEnabled = payload;
  },
  UPDATE_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
  UPDATE_CURRENCY(state, payload) {
    state.currency = payload;
  },
  UPDATE_CURRENCIES(state, payload) {
    state.currencies = payload;
  },
  UPDATE_CURRENT_THEME(state, payload) {
    state.currentTheme = payload;
  },
  UPDATE_APPROVAL_SETTINGS(state, payload) {
    state.approvalSettings = payload;
  }
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async getSettings({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/settings`);
      if (response) {
        commit(
          "UPDATE_PUSH_NOTIFICATION_STATE",
          response.data.accounts[0].isActive
        );
        commit("UPDATE_SETTINGS", response.data);
        const theme = response.data.display[0].variants.find(
          variant => variant.isActive
        );
        commit("UPDATE_CURRENT_THEME", theme);
        localStorage.setItem("theme", JSON.stringify(theme));
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            "Unable to get system default settings",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async getApprovalSettings({ commit, dispatch }) {
    try {
      const response = await Api().get(`/settings/approvals/list`);
      if (response) {
        commit("UPDATE_APPROVAL_SETTINGS", response.data);
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            "Unable to get system default approval settings",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async updateNotificationToken({ commit }, payload) {
    await Api().post(`/notifications/tokens`, payload);
  },
  async turnOnPushNotification({ commit, state, dispatch }, payload) {
    try {
      state.settings.accounts[0].isActive = true;
      const response = await Api().put(`/settings`, { ...state.settings });
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Order successfully created", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        dispatch("getSettings");
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get list of vehicles", {
        root: true
      });
      commit("UPDATE_STATUS", "success", { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async notifications({ commit, dispatch, state }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/notifications?take=${payload.take}&offset=${payload.offset}`
      );
      if (response) {
        commit("UPDATE_NOTIFICATIONS", response.data);
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get fetch notifications",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async markNotificationAsRead({ commit, dispatch }, id) {
    try {
      const response = await Api().put(`/notifications/${id}/mark/as/read`);
      if (response) {
        dispatch("notifications", { take: 20, offset: 0 });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to communicate with server",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async systemCurrency({ commit }) {
    try {
      const response = await Api().get(`/settings/system/currency`);
      if (response) {
        commit("UPDATE_CURRENCY", response.data.currency);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to communicate with server",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async updateSettings({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(`/settings`, payload.settings);
      if (response) {
        dispatch("isLoading", false, { root: true });
        await Api().post("/settings/approvals/list", payload.approvals);
        dispatch("getSettings");
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `System settings updated successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to communicate with server",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async updateOrganization({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(`/organizations/${payload.id}`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("getSettings");
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message:
              response.data?.message ?? "Organization successfully updated",
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            "Unable to update organization details",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async listOfCurrencies({ commit, dispatch }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response$ = from(
        (
          await Axios.get(
            `${process.env.VUE_APP_RESTCOUNTRIES_URL}/rest/v2/all?fields=currencies`
          )
        ).data
      ).pipe(
        map(currency => currency.currencies),
        reduce(
          (totalArray, currentValue) => totalArray.concat(currentValue),
          []
        )
      );
      response$
        .subscribe(subscribe => {
          commit("UPDATE_CURRENCIES", subscribe);
          dispatch("isListLoading", false, { root: true });
        })
        .unsubscribe();
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            "Unable to get system default settings",
          status: "red"
        },
        { root: true }
      );
    }
  }
};

const getters = {
  getIsNotificationTurnedOn: state => state.isPushNotificationEnabled,
  getNotifications: state => state.notifications,
  getSystemCurrency: state => state.currency,
  getSystemSettings: state => state.settings,
  getApprovalSettings: state => state.approvalSettings,
  getAllCurrencies: state => state.currencies,
  getCurrentTheme: state => state.currentTheme
};

export const settings = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
