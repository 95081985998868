import Vue from "vue";
import Vuex from "vuex";
import { Ability } from "@casl/ability";
Vue.use(Vuex);

import { transport } from "./transport";
import { products } from "./products";
import { orders } from "./orders";
import { settings } from "./settings";
import { deliveries } from "./deliveries";
import { stocks } from "./stocks";
import { prices } from "./prices";
import { customers } from "./customers";
import { auth } from "./auth";
import { suppliers } from "./suppliers";
import { dashboard } from "./dashboard";
import { acl } from "./acl";
import { users } from "./users";
import { branches } from "./branches";
import { sales } from "./sales";
import defineAbility from "./ability";
import createPersistedState from "vuex-persistedstate";
import { reports } from "./reports";
import { stocklevels } from "./stocklevels";
import { exportReport } from "./exports";
export const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ["permissions"]
    }),
    defineAbility
  ],
  state: {
    showSnackBar: false,
    message: "",
    status: "",
    timeout: 4000,
    isPageLoading: false,
    isLoading: false,
    isSubLoading: false,
    isListLoading: false,
    isNextLoad: 0,
    resetFormState: false,
    permissions: [],
    dialogState: {
      add: false,
      edit: false,
      delete: false,
      details: false,
      generate: false,
      price: false,
      logout: false,
      alert: false
    }
  },
  mutations: {
    UPDATE_SNACKBAR(state, payload) {
      state.showSnackBar = payload;
    },
    UPDATE_MESSAGE(state, payload) {
      state.message = payload;
    },
    UPDATE_STATUS(state, payload) {
      state.status = payload;
    },
    UPDATE_PAGELOADING(state, payload) {
      state.isPageLoading = payload;
    },
    UPDATE_LOADING(state, payload) {
      state.isLoading = payload;
    },
    UPDATE_SUB_DIALOG_LOADING(state, payload) {
      state.isSubLoading = payload;
    },
    UPDATE_LIST_LOADING(state, payload) {
      state.isListLoading = payload;
    },
    UPDATE_LOAD_NEXT(state, payload) {
      state.isNextLoad = payload;
    },
    UPDATE_RESET_FORM_STATE(state, payload) {
      state.resetFormState = payload;
    },
    UPDATE_DIALOG_STATE(state, payload) {
      state.dialogState[payload.idx] = payload.status;
    },
    UPDATE_PERMISSIONS(state, payload) {
      state.permissions = payload;
    }
  },
  actions: {
    isLoading({ commit }, payload) {
      commit("UPDATE_LOADING", payload);
    },
    isNextLoad({ commit }, payload) {
      commit("UPDATE_LOAD_NEXT", payload);
    },
    isPageLoading({ commit }, payload) {
      commit("UPDATE_PAGELOADING", payload);
    },
    isSubLoading({ commit }, payload) {
      commit("UPDATE_SUB_DIALOG_LOADING", payload);
    },
    isListLoading({ commit }, payload) {
      commit("UPDATE_LIST_LOADING", payload);
    },
    resetFormState({ commit }, payload) {
      commit("UPDATE_RESET_FORM_STATE", payload);
    },
    updateDialog({ commit }, payload) {
      commit("UPDATE_DIALOG_STATE", payload);
      setTimeout(() => {
        commit("UPDATE_SNACKBAR", false);
      }, 3000);
    },
    showSnackBar({ commit }, payload) {
      const { snackbar, message, status } = payload;
      commit("UPDATE_SNACKBAR", snackbar);
      commit("UPDATE_MESSAGE", message);
      commit("UPDATE_STATUS", status);
      setTimeout(() => {
        commit("UPDATE_SNACKBAR", false);
        commit("UPDATE_MESSAGE", "");
        commit("UPDATE_STATUS", "black");
      }, 4000);
    },
    setPermissions({ commit }, payload) {
      commit("UPDATE_PERMISSIONS", payload);
    }
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getIsSubLoading: state => state.isSubLoading,
    getIsListLoading: state => state.isListLoading,
    getIsPageLoading: state => state.isPageLoading,
    getDialogState: state => id => state.dialogState[id],
    getSnackBarState: state => state.showSnackBar,
    getSnackBarMessage: state => state.message,
    getSnackBarStatus: state => state.status,
    getResetFormState: state => state.resetFormState,
    getSnackBarTimeout: state => state.timeout,
    getLoadNextStatus: state => state.isNextLoad,
    ability: () => new Ability()
  },
  modules: {
    transport,
    products,
    orders,
    settings,
    deliveries,
    stocks,
    prices,
    customers,
    auth,
    suppliers,
    dashboard,
    acl,
    users,
    branches,
    sales,
    reports,
    stocklevels,
    exportReport
  }
});
