import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSwal from "vue-swal";
import socketio from "socket.io-client";
import VueSocketIO from "vue-socket.io";
import VueApexCharts from "vue-apexcharts/dist/vue-apexcharts";
import { abilitiesPlugin } from "@casl/vue";
import "./config/firebase.config";

import "./assets/css/main.css";
import { store } from "./store/index";
import "./registerServiceWorker";

Vue.component("apexcharts", VueApexCharts);
export const SocketInstance = socketio("http://localhost:8099/");
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: SocketInstance,
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
);
Vue.use(abilitiesPlugin, store.getters.ability);

Vue.config.productionTip = false;
Vue.use(VueSwal);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
