import { Api } from "../api/Api";

const state = {
  paginate: {
    total: 0,
    page: 1,
    limit: 10
  },
  deliveries: [],
  details: null,
  remainingQuantity: null
};

const mutations = {
  UPDATE_DELIVERIES(state, payload) {
    state.deliveries = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_REMAINING_QTY(state, payload) {
    state.remainingQuantity = payload;
  }
};
const actions = {
  async list({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/deliveries?isPaginated=true&page=${payload.page}&limit=${payload.itemsPerPage}`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        const { page, limit, total, docs } = response.data.paginateObj;
        commit("UPDATE_PAGINATION", { page, limit: parseInt(limit), total });
        commit("UPDATE_DELIVERIES", docs);
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get deliveries", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().get(`/deliveries/${payload.id}`);
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_DETAILS", response.data);
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get delivery details", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async remainingQuantity({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(
        `/deliveries/share/remaining/${payload.id}/quantity`
      );
      if (response) {
        commit("UPDATE_REMAINING_QTY", response.data);
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get remaining quantities to share",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async settleDisparities({ dispatch, state }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id, ...rest } = payload;
      const response = await Api().put(
        `/deliveries/${id}/settle/shared/quantity/disparities`,
        { ...rest, changedQuantity: Number(rest.changedQuantity) }
      );
      if (response) {
        dispatch(
          "updateDialog",
          {
            idx: "edit",
            status: false
          },
          { root: true }
        );

        dispatch("list", {
          page: state.paginate.page,
          itemsPerPage: state.paginate.limit
        });
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Delivery quantity changed successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            `Unable to settle disparities in quantity sharing`,
          status: "red"
        },
        { root: true }
      );
    }
  }
};

const getters = {
  getAllDeliveries: state => state.deliveries,
  getDeliveryDetails: state => state.details,
  getPagination: state => state.paginate,
  getRemainingQuantity: state => state.remainingQuantity
};

export const deliveries = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
