export default store => {
  const ability = store.getters.ability;
  ability.update(store.state.permissions);

  return store.subscribe(mutation => {
    switch (mutation.type) {
      case "UPDATE_PERMISSIONS":
        ability.update(mutation.payload);
        break;
    }
  });
};
