<template>
  <v-app light id="app">
    <router-view></router-view>
    <v-snackbar
      v-model="snackbar"
      multi-line
      color="primary"
      :timeout="4000"
      right
    >
      <span class="font font-weight-bold font-size-md">{{ title }}</span>
      <p class="font font-weight-medium font-size-md">
        {{ text.length > 30 ? text.slice(0, 30) + "..." : text }}
      </p>
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          <span class="font font-weight-medium text-sm">Close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
/* eslint-disable no-unused-vars */

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
export default {
  name: "App",
  data: () => ({
    snackbar: false,
    text: "",
    title: ""
  }),
  mounted() {
    const messaging = firebase.messaging();
    messaging.onMessage(payload => {
      this.snackbar = true;
      const { notification } = payload;
      this.title = notification.title;
      this.text = notification.body;
    });

    navigator.serviceWorker.addEventListener("message", event => {
      this.$store.dispatch("settings/notifications", { take: 20, offset: 0 });
    });
  },
  created() {}
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
