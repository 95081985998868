/* eslint-disable no-unused-vars */
import { Api } from "../api/Api";

const state = {
  products: [],
  reset: {
    name: "",
    description: ""
  },
  paginate: {
    total: 0,
    page: 1,
    limit: 10
  },
  cars: [],
  newlyAddedProductBranches: []
};

const mutations = {
  UPDATE_PRODUCTS(state, payload) {
    state.products = payload;
  },
  UPDATE_CARS(state, payload) {
    state.cars = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_NEWLY_ADDED_PRODUCT_BRANCHES(state, payload) {
    state.newlyAddedProductBranches = payload;
  }
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async list({ dispatch, commit }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/products?paginated=${payload.isPaginated ?? true}&page=${
          payload.page
        }&limit=${payload.itemsPerPage}`
      );
      if (response) {
        if (payload.isPaginated ?? true) {
          const { page, limit, total, docs } = response.data.paginateObj;
          commit("UPDATE_PAGINATION", { page, limit, total });
          commit("UPDATE_PRODUCTS", docs);
        } else {
          commit("UPDATE_PRODUCTS", response.data);
        }
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get list of products", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async create({ dispatch, commit, state }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/products`, payload.body);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("list", {
          page: state.paginate?.page,
          itemsPerPage: state.paginate?.limit
        });
        dispatch("resetFormState", true, { root: true });
        dispatch("updateDialog", payload.loading, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Product created successfully", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to add new vehicle", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async addOpeningStock({ dispatch, commit }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { productId, ...rest } = payload;
      const response = await Api().post(
        `/products/lubes/${productId}/add/opening/stock`,
        rest
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Opening stock added successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch("newlyAddedProductBranches", productId);
        dispatch("resetFormState", true, { root: true });
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? "Unable to add opening stock",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async newlyAddedProductBranches({ dispatch, commit }, productId) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().get(
        `/products/lubes/${productId}/stock/existing/branches`
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit(
          "UPDATE_NEWLY_ADDED_PRODUCT_BRANCHES",
          response?.data?.map(({ id, ...rest }) => rest.branchId)
        );
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            "Unable to get existing branches for new products",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  }
};

const getters = {
  getPagination: state => state.paginate,
  getProductList: state => state.products,
  getResetFormValues: state => state.reset,
  getNewlyAddedProductBranches: state => state.newlyAddedProductBranches
};

export const products = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
