/* eslint-disable no-unused-vars */
import { Api } from "../api/Api";

const state = {
  orders: [],
  branches: [],
  products: [],
  paginate: {
    total: 0,
    page: 1,
    limit: 10
  },
  reset: {},
  orderDetails: null,
  resetFormValues: []
};

const mutations = {
  UPDATE_ORDERS(state, payload) {
    state.orders = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_BRANCHES(state, payload) {
    state.branches = payload;
  },
  UPDATE_PRODUCTS(state, payload) {
    state.products = payload;
  },
  UPDATE_ORDER_DETAILS(state, payload) {
    state.orderDetails = payload;
  }
};

const actions = {
  async list({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const query = payload?.filter ? `&filter=${payload.filter}` : "";
      const response = await Api().get(
        `/orders?paginated=true&page=${payload.page}&limit=${payload.itemsPerPage}${query}`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        const { page, limit, total, docs } = response.data.paginateObj;
        commit("UPDATE_PAGINATION", { page, limit, total });
        commit("UPDATE_ORDERS", docs);
        //todo change the logic here for complete order and move it to the backend
        docs
          .filter(order => !order.isOrderCompleted && order.isOrderReviewed)
          .forEach(order => {
            const acceptedProductDeliveryAmount = order?.productDeliveries
              ? order.productDeliveries
                  .filter(delivery => delivery.isDeliveryAccepted)
                  .reduce((acc, item) => (acc += item.quantity), 0)
              : 0;
            if (acceptedProductDeliveryAmount === order.quantity) {
              dispatch("markOrderAsCompleted", { id: order.id });
            }
          });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get list of orders", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async filterDetailsByID({ commit, state, dispatch }, payload) {
    const response = state.orders.find(order => {
      return order.id === payload.id;
    });
    commit("UPDATE_ORDER_DETAILS", response);
    const acceptedProductDeliveryAmount = response.productDeliveries
      ? response.productDeliveries
          .filter(delivery => delivery.isDeliveryAccepted)
          .reduce((acc, item) => (acc += item.quantity), 0)
      : 0;
    if (
      !response.isOrderCompleted &&
      acceptedProductDeliveryAmount === response.quantity
    ) {
      dispatch("markOrderAsCompleted", { id: response.id });
    }
  },
  /**
   * Find details of order line items as the main order
   */
  async details({ state, dispatch, commit }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/orders/line/item/${payload.id}`);
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_ORDER_DETAILS", response.data);
        const acceptedProductDeliveryAmount = response.data?.productDeliveries
          ? response.data.productDeliveries
              .filter(delivery => delivery.isDeliveryAccepted)
              .reduce((acc, item) => (acc += item.quantity), 0)
          : 0;
        if (
          response.data.isOrderCompleted &&
          acceptedProductDeliveryAmount === response.data.quantity
        ) {
          dispatch("markOrderAsCompleted", { id: response.data.id });
        }
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get order details", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async branchList({ commit, dispatch }) {
    try {
      const response = await Api().get(`/branches`);
      if (response) {
        commit("UPDATE_BRANCHES", response.data);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get list of orders", {
        root: true
      });
      commit("UPDATE_STATUS", "success", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async productList({ commit, dispatch }) {
    try {
      const response = await Api().get(`/products`);
      if (response) {
        commit("UPDATE_PRODUCTS", response.data);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get list of orders", {
        root: true
      });
      commit("UPDATE_STATUS", "success", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async filterByMonth({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const query = payload?.filter ? `&filter=${payload.filter}` : "";
      const response = await Api().get(
        `/orders/filter/by/month/${payload.tag}?isPaginated=true&page=${payload.page}&limit=${payload.limit}${query}`
      );
      if (response) {
        const { page, limit, total, docs } = response.data.paginateObj;
        commit("UPDATE_PAGINATION", { page, limit, total });
        commit("UPDATE_ORDERS", docs);
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get create an order", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async createOrder({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/orders`, payload.body);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("updateDialog", payload.loading, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Order successfully created", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        dispatch("list", {
          page: state.paginate.page,
          itemsPerPage: state.paginate.limit
        });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e.response.data?.message ?? "Unable to get create an order",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async updateOrder({ state, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { orderId, ...rest } = payload.body;
      const response = await Api().put(`/orders/${orderId}`, rest);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("list", {
          page: state.paginate.page,
          itemsPerPage: state.paginate.limit
        });
        dispatch("updateDialog", payload.loading, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message:
              response?.data?.message ?? `Order details updated successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message.join(",")
            : e?.response?.data?.message ?? `Unable to update order`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async deleteOrder({ state, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().delete(`/orders/${payload.id}`);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("list", {
          page: 1,
          itemsPerPage: state.paginate.limit
        });
        dispatch(
          "updateDialog",
          { idx: "delete", state: false },
          { root: true }
        );
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message:
              response?.data?.message ?? `Order details deleted successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message.join(",")
            : e?.response?.data?.message ?? `Unable to delete order`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async generateDeliveries({ state, commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/deliveries`, payload.body);
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Deliveries generated successfully", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        dispatch("list", {
          page: state.paginate.page,
          itemsPerPage: state.paginate.limit
        });
        dispatch("details", {
          id: payload.body?.remainingQuantity?.orderLineItemId
        });
        dispatch(
          "deliveries/remainingQuantity",
          {
            id: payload.body?.remainingQuantity?.orderLineItemId
          },
          { root: true }
        );
        dispatch("resetFormState", true, { root: true });
        if (payload.dialog.idx === "generate") {
          dispatch(
            "updateDialog",
            { idx: "generate", state: false },
            { root: true }
          );
        }
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get order details", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async markOrderAsCompleted({ state, dispatch }, payload) {
    try {
      await Api().put(`/deliveries/mark/order/${payload.id}/as/completed`);
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
    }
  }
};

const getters = {
  getAllOrders: state => state.orders,
  getOrderDetails: state => state.orderDetails,
  getAllBranches: state => state.branches,
  getAllProducts: state => state.products,
  getPagination: state => state.paginate,
  getResetFormValues: state => state.resetFormValues
};

export const orders = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
