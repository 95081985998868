export const REPORTS_DROPDOWN = [
  {
    id: "OVERALL_SALES_LIST",
    name: "OVERALL TOTAL SALES AND EXPENSES",
    fileName: "OSL",
    duration: {
      monthly: false
    }
  },
  {
    id: "SHOP_SALES",
    name: "SHOP SALES, MD ALLOCATION USED & PAYMENT TRENDS",
    fileName: "SSMD",
    duration: {
      monthly: false
    }
  },
  {
    id: "LUBE_SALES",
    name: "LUBE DAILY REPORT FOR QTY SOLD & LEFT OVER PERIOD",
    fileName: "LDROP",
    duration: {
      monthly: false
    }
  },
  {
    id: "DAILY_OPENING_AND_CLOSING_LUBE_SALES",
    name: "DAILY OPENING AND CLOSING STOCKs FOR LUBE SALES",
    fileName: "DOACSS",
    duration: {
      monthly: true
    }
  },
  {
    id: "DAILY_CREDIT_SALES_BY_CUSTOMERS",
    name: "DAILY CREDIT SALES BY CUSTOMERS",
    fileName: "CRED-SALE",
    duration: {
      daily: true
    }
  },
  {
    id: "DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS",
    name: "DAILY CREDIT COLLECTIONS BY CUSTOMERS",
    fileName: "CRED-COLL",
    duration: {
      daily: true
    }
  },
  {
    id: "MD_ALLOCATION_ADDED_USERS",
    name: "MD's ALLOCATION ADDED BY USERS",
    fileName: "MD-ALLOT",
    duration: {
      daily: true
    }
  },
  {
    id: "SHOP_SALES_PURCHASES_AND_PAYMENTS",
    name: "SHOP SALES CASH PURCHASES, CREDIT PURCHASES, CREDIT PAYMENTS",
    fileName: "PR-AMT",
    duration: {
      daily: true
    }
  },
  {
    id: "LUBES_GENERAL_MONTHLY_REPORTS",
    name: "LUBES GENERAL MONTHLY REPORTS",
    fileName: "LB-MR",
    duration: {
      daily: false
    }
  },
  {
    id: "FUEL_ORDERS_GENERAL_REPORTS",
    name: "FUEL ORDERS GENERAL REPORTS",
    fileName: "FL-GR",
    duration: {
      daily: false
    }
  },
  {
    id: "DAILY_EXPENSES_OVER_PERIOD",
    name: "DAILY EXPENSES",
    fileName: "DE-",
    duration: {
      daily: false
    }
  },
  {
    id: "REVENUE_BY_CHANNELS",
    name: "REVENUE BY PAYMENT METHOD eg. MOMO, CARDs etc",
    fileName: "RPM-",
    duration: {
      daily: false
    }
  },
  {
    id: "MD_BILLS",
    name: "MD BILLS",
    fileName: "MD-BILLS-",
    duration: {
      daily: false
    }
  },
  {
    id: "CUSTOMERS_LIST",
    name: "ALL CUSTOMERS LIST (SUPPLIERS, INDIVIDUALS, CUSTOMERS)",
    fileName: "CUSTOMERS",
    duration: {
      daily: false
    }
  },
  {
    id: "RTT_LIST",
    name: "RTT REPORTS",
    fileName: "-RTT",
    duration: {
      daily: false
    }
  },
  {
    id: "MEASURED_STOCK_REPORTS",
    name: "MEASURED STOCK LEVEL REPORTS (VARIATIONS)",
    fileName: "-VARIATIONS",
    duration: {
      daily: false
    }
  },
  {
    id: "LUBE_ORDERS_TRANSFERS_AND_DAMAGES",
    name: "LUBE ORDERS - TRANSFERS AND DAMAGES",
    fileName: "-TRS",
    duration: {
      daily: false
    }
  },
  {
    id: "PUMP_ATTENDEES_WITH_DAILY_SALES",
    name: "PUMP ATTENDEES WITH DAILY SALES REPORTS",
    fileName: "-PDSR",
    duration: {
      daily: false
    }
  }
];
export const OVERALL_SALES_TITLE = [
  { text: "Date", value: "date" },
  { text: "Amt.(Shops)", value: "totalShopSales" },
  { text: "Amt.(Expenses)", value: "totalExpenses" },
  { text: "Amt.(Bills)", value: "totalBills" },
  { text: "Amt.(Fuel Sales)", value: "totalFuelSales" },
  { text: "Amt.(Lube Sales)", value: "totalLubeSales" },
  { text: "Amt.(Credit Sales)", value: "totalCreditSales" },
  { text: "Amt.(Credit Collection)", value: "totalCreditCollection" },
  { text: "RecordedBy", value: "recordedBy" }
];

export const SHOP_SALES_AND_ALLOCATION_TITLE = [
  { text: "Date", value: "salesDate" },
  { text: "Sales Amt.", value: "salesAmount" },
  { text: "Cash Deposit", value: "cashDeposit" },
  { text: "Cash Purchases", value: "cashPurchases" },
  { text: "Cash Payments", value: "cashPayment" },
  { text: "Credit Payments", value: "creditPayments" },
  { text: "Credit Purchases", value: "creditPurchases" },
  { text: "Sales Amt. Used", value: "salesAmountUsed" },
  { text: "MD Allocation Used", value: "mdAllocationAmountUsed" }
];

export const LUBE_SALES_TITLE = [
  { text: "Name", value: "productName" },
  { text: "Total Sold", value: "totalSold" },
  { text: "Total Left", value: "stockAtCurrentPeriod" }
];

export const DAILY_OPENING_AND_CLOSING_LUBE_SALES_TITLE = [
  { text: "Product Name", value: "productName" },
  { text: "Opening Stock", value: "openingStock" },
  { text: "Closing Stock", value: "closingStock" },
  { text: "Qty Sold", value: "quantitySold" },
  { text: "Prod. Litres", value: "productLitres" },
  { text: "Total Sellout (Litres)", value: "totalSelloutInLitres" }
];

export const DAILY_CREDIT_SALES_BY_CUSTOMERS = [
  { text: "Sales Date", value: "salesDate" },
  { text: "Customer Name", value: "customerName" },
  {
    text: "Credit Sales Amt.",
    value: "creditSales"
  },
  { text: "Is Business", value: "isBusiness" }
];

export const DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS = [
  { text: "Sales Date", value: "salesDate" },
  { text: "Customer Name", value: "customerName" },
  {
    text: "Credit Collection Amt.",
    value: "creditCollection"
  },
  { text: "Is Business", value: "isBusiness" }
];

export const MD_ALLOCATION_ADDED_USERS = [
  { text: "Date", value: "date" },
  { text: "Amt Added", value: "amount" },
  { text: "Total Amt. Before", value: "totalAmountBefore" },
  { text: "Total Amt. After", value: "currentTotalAmount" },
  { text: "Acc. Holder Name", value: "name" },
  { text: "Acc. Holder Email", value: "email" }
];

export const SHOP_SALES_PURCHASES_AND_PAYMENTS = [
  { text: "Date", value: "date" },
  { text: "Supplier Name", value: "supplierName" },
  { text: "Cash Purchases", value: "cashPurchases" },
  { text: "Cash Purchase Inv.", value: "cashPurchasesInvoice" },
  { text: "Credit Purchases", value: "creditPurchases" },
  { text: "Credit Purchases Inv.", value: "creditPurchasesInvoice" },
  { text: "Cash/Credit Payments", value: "cashOrCreditPayments" },
  { text: "Cash/Credit Pay. Inv.", value: "cashOrCreditPaymentsInvoice" }
];

export const LUBES_GENERAL_MONTHLY_REPORTS = [
  { text: "Date", value: "date" },
  { text: "Description", value: "description" },
  { text: "Opening Stock", value: "openingStock" },
  { text: "Closing Stock", value: "closingStock" },
  { text: "Qty. Sold", value: "quantitySold" },
  { text: "Sales Value", value: "salesValue" },
  { text: "Product Litres", value: "productLitres" },
  { text: "Total Sold In Litres.", value: "totalSellOutInLitres" }
];

export const FUEL_ORDERS_GENERAL_REPORTS = [
  { text: "Date", value: "date" },
  { text: "Invoice #", value: "invoiceNumber" },
  { text: "Cheque #", value: "chequeNumber" },
  { text: "Cheque Amt.", value: "chequeAmount" },
  { text: "Product Name", value: "productName" },
  { text: "Qty(Ltrs)", value: "quantity" },
  { text: "Net Amt.", value: "netAmount" },
  { text: "Ordered Branch", value: "orderedBranchName" },
  { text: "Car Reg.No.", value: "carRegistrationNumber" },
  { text: "Driver Name", value: "driverName" },
  { text: "Order Status", value: "orderStatus" },
  { text: "Product Deliveries", value: "productDeliveries" }
];

export const DAILY_EXPENSES_OVER_PERIOD = [
  { text: "Date", value: "salesDate" },
  { text: "Amount Spent", value: "value" },
  { text: "Purpose Of Spent", value: "name" },
  { text: "Comment", value: "comment" }
];

export const REVENUE_BY_CHANNELS = [
  { text: "Date", value: "salesDate" },
  { text: "Mode Of Payment", value: "modeName" },
  { text: "Total Amount", value: "amount" }
];

export const MD_BILLS = [
  { text: "Date", value: "date" },
  { text: "Amount Paid", value: "amount" },
  { text: "Reason", value: "name" }
];

export const findTableHeadingForCustomers = customerType => {
  switch (customerType) {
    case "SUPPLIERS":
      return SUPPLIERS_LIST;
    case "CUSTOMERS":
      return [
        ...CUSTOMERS_LIST.slice(0, 3),
        { text: "Bal/Amount", value: "amount" }
      ];
    case "BUSINESSES":
      return CUSTOMERS_LIST;
  }
};
export const CUSTOMERS_LIST = [
  { text: "Name", value: "name" },
  { text: "Contact", value: "contact" },
  { text: "Address", value: "address" },
  { text: "Contact Person Name", value: "contactPersonName" },
  { text: "Contact Person Phone", value: "contactPersonPhone" },
  { text: "Bal/Amount", value: "amount" }
];

export const SUPPLIERS_LIST = [
  ...CUSTOMERS_LIST.filter(({ value }) => value !== "amount"),
  { text: "Credit Purchase Amt.", value: "creditPurchasesAmount" }
];

export const RTT_LIST = [
  { text: "Date", value: "salesDate" },
  { text: "Product", value: "productName" },
  { text: "Litres", value: "litres" },
  { text: "Recorded By", value: "recordedBy" }
];

export const MEASURED_STOCK_REPORTS = [
  { text: "Date", value: "date" },
  { text: "Product", value: "name" },
  { text: "Physical Stock Level(Lts)", value: "physicalStockLevel" },
  { text: "System Stock Level(Lts)", value: "systemStockLevel" },
  { text: "Stock Variation(Lts)", value: "stockVariation" },
  { text: "Stock Sold(Lts)", value: "stocksSold" },
  { text: "Recorded By", value: "recordedBy" }
];

export const LUBE_ORDERS_TRANSFERS_AND_DAMAGES = [
  { text: "Date", value: "orderDate" },
  { text: "Product", value: "productName" },
  { text: "Transfers In", value: "transferQuantityIn" },
  { text: "Transfers Out", value: "transferQuantityOut" },
  { text: "Comment", value: "transferComment" },
  { text: "Damages Qty", value: "damagesQuantity" }
];

export const PUMP_ATTENDEES_WITH_DAILY_SALES = [
  { text: "Sales Date", value: "salesDate" },
  { text: "Attendant Name", value: "attendant" },
  { text: "Pump", value: "pumpName" },
  { text: "Nozzle", value: "nozzleName" },
  { text: "Product Name", value: "productName" },
  {
    text: "Meter Sold(Ltrs)",
    value: "meterSold"
  },
  { text: "Total Amt.(GHS)", value: "totalAmountSold" }
];
