import { Api } from "../api/Api";
import { from } from "rxjs";
import { mergeMap, groupBy, reduce, toArray } from "rxjs/operators";
import moment from "moment";
const state = {
  pendingShopSales: null,
  pendingLubeSales: [],
  lubeSaleSegregation: [],
  pendingFuelSales: [],
  recordedFuelSales: [],
  overAllSalesCalculations: null,
  shopSalesReset: {
    cashDeposit: 0,
    isApproved: false,
    isReviewed: false,
    mdsAllocationAmount: 0,
    salesAmount: 0,
    salesDate: null,
    supplierSalesRecords: []
  }
};

const mutations = {
  UPDATE_PENDING_SHOP_SALES(state, payload) {
    state.pendingShopSales = payload;
  },
  UPDATE_PENDING_LUBE_SALES(state, payload) {
    state.pendingLubeSales = payload;
  },
  UPDATE_LUBE_SALE_SEGREGATION(state, payload) {
    state.lubeSaleSegregation = payload;
  },
  UPDATE_PENDING_FUEL_SALES(state, payload) {
    state.pendingFuelSales = payload;
  },
  UPDATE_OVERALL_SALES(state, payload) {
    state.overAllSalesCalculations = payload;
  },
  UPDATE_RECORDED_FUEL_SALES(state, payload) {
    state.recordedFuelSales = payload;
  }
};
const actions = {
  async pendingShopSalesList({ state, commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(
        `/sales/shops/close/daily/pending/approvals?date=${payload.date}&branchId=${payload.branchId}`
      );
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit(
          "UPDATE_PENDING_SHOP_SALES",
          response.data !== "" ? response.data : state.shopSalesReset
        );
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            `Unable to get list of pending shop sales`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async pendingLubeSalesList({ commit, dispatch }, payload) {
    try {
      const { branchId, date } = payload;
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(
        `/sales/daily/lubes/pending/${branchId}/approvals?isPaginated=false&date=${date}`
      );
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_PENDING_LUBE_SALES", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            `Unable to get list of pending shop sales`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async customerCreditLubeSalesList({ commit, dispatch }, payload) {
    try {
      const { branchId, date } = payload;
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(
        `/sales/daily/${date}/lubes/for/customer/credit/sales?branchId=${branchId}`
      );
      if (response) {
        commit("UPDATE_LUBE_SALE_SEGREGATION", response.data);
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            `Unable to get list of pending shop sales`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async pendingFuelSalesList({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { branchId, date } = payload;
      const response$ = from(
        (
          await Api().get(
            `/sales/fuels/recorded/sales/pending/approvals?isPaginated=false&branchId=${branchId}&date=${date}`
          )
        ).data
      ).pipe(
        groupBy(group => group.pumpAssignAttendantId.attendantId.id),
        mergeMap(group =>
          group.pipe(
            reduce(
              (acc, item) => {
                acc.values.push({
                  ...item,
                  isStockAvailable:
                    item?.nozzleId?.productId?.stockId?.find(stock =>
                      moment(stock.date, "YYYY-MM").isSame(
                        moment(date, "YYYY-MM")
                      )
                    ) ?? null,
                  price:
                    item?.nozzleId?.productId?.prices?.find(
                      price => price.isCurrent
                    )?.amount ?? 0
                });
                return acc;
              },
              { key: group.key, values: [] }
            )
          )
        ),

        toArray()
      );
      response$
        .subscribe(sales => {
          dispatch("isPageLoading", false, { root: true });
          commit("UPDATE_PENDING_FUEL_SALES", sales);
        })
        .unsubscribe();
      await dispatch("overallSalesForEndOfDay", { branchId, date });
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? `Unable to fetch pending fuel sales `,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async overallSalesForEndOfDay({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { branchId, date } = payload;
      const response$ = await Api().get(
        `/sales/overall/records/for/end/of/day?branchId=${branchId}&date=${date}`
      );
      if (response$) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_OVERALL_SALES", !response$.data ? null : response$.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message:
            e?.response?.data?.message ??
            `Unable to get list of sales for end of day`,
          snackbar: true,
          status: "red"
        },
        { root: true }
      );
    }
  },
  // getting already recorded fuel sales // can be halfway
  async recordedFuelSales({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { date, branchId } = payload;
      const response = await Api().get(
        `/sales/fuels/by/date/${date}?isPaginated=false&page=1&limit=15&branchId=${branchId}`
      );
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_RECORDED_FUEL_SALES", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "snackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            `Unable to get already recorded fuel sales`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  // eslint-disable-next-line no-unused-vars
  async approvalOfDailyShopSales({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { saleId, branchId, ...rest } = payload;
      const response = await Api().put(
        `/sales/shops/close/daily/${saleId}/approvals?branchId=${branchId}`,
        rest
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("isNextLoad", 200, { root: true });
        dispatch(
          "updateDialog",
          {
            idx: "alert",
            state: false
          },
          { root: true }
        );
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message:
              response.data?.message ?? `Shop sales approved successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch("isNextLoad", 500, { root: true });
      dispatch(
        "updateDialog",
        {
          idx: "alert",
          state: false
        },
        { root: true }
      );
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? `Unable to approve shop sales`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async approvalOfDailyLubeSales({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { branchId, body } = payload;
      const { salesDate } = body;
      const response = await Api().put(
        `/sales/daily/${salesDate}/lubes/approval?branchId=${branchId}`,
        body
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("isNextLoad", 200, { root: true });
        dispatch(
          "updateDialog",
          {
            idx: "details",
            state: false
          },
          { root: true }
        );
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message:
              response.data?.message ?? `Lube sales approved successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isNextLoad", 500, { root: true });
      dispatch("isLoading", false, { root: true });
      dispatch(
        "updateDialog",
        {
          idx: "details",
          state: false
        },
        { root: true }
      );
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message?.join(",")
            : e.response.data.message,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async approvalOfFuelSales({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { branchId, ...rest } = payload;
      const response = await Api().put(
        `/sales/fuels/recorded/closing/daily/approvals?branchId=${branchId}&date=${payload.closingSaleDate}`,
        rest
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("isNextLoad", 200, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `End of day sales for ${moment().format(
              "YYYY-MM-DD"
            )} have been approved successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch("updateDialog", { idx: "edit", state: false }, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch("isNextLoad", 500, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message?.join(",")
            : e.response.data.message,
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getAllPendingShopSales: state => state.pendingShopSales,
  getAllPendingLubeSales: state => state.pendingLubeSales,
  getAllLubeSaleSegregation: state => state.lubeSaleSegregation,
  getAllPendingFuelSales: state => state.pendingFuelSales,
  getAllOverallSalesCalculation: state => state.overAllSalesCalculations,
  getAlreadyRecordedFuelSales: state => state.recordedFuelSales
};

export const sales = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
