import { Api } from "../api/Api";
const state = {
  businesses: [],
  individuals: [],
  details: null,
  searchResults: [],
  paginate: {
    businesses: {
      total: 0,
      limit: 10,
      page: 1
    },
    individuals: {
      total: 0,
      limit: 10,
      page: 1
    }
  }
};
const mutations = {
  UPDATE_BUSINESSES(state, payload) {
    state.businesses = payload;
  },
  UPDATE_INDIVIDUALS(state, payload) {
    state.individuals = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_SEARCH_RESULTS(state, payload) {
    state.searchResults = payload;
  }
};
const actions = {
  async businesses({ commit, dispatch, state }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { page, limit, filters } = payload;
      const response = await Api().get(
        `/customers/businesses?isPaginated=true&page=${page}&limit=${limit}&filters=${filters}`
      );
      if (response) {
        const { docs, page, limit, total } = response.data.paginateObj;
        commit("UPDATE_BUSINESSES", docs);
        const { individuals } = state.paginate;
        commit("UPDATE_PAGINATION", {
          businesses: { page, limit: parseInt(limit), total },
          individuals: {
            page: individuals.page,
            limit: individuals.limit,
            total: individuals.total
          }
        });
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get business customers",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async individuals({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { filters, page, limit } = payload;
      const response = await Api().get(
        `/customers?isPaginated=true&page=${page}&limit=${limit}&filters=${filters}`
      );
      if (response) {
        const { docs, page, limit, total } = response.data.paginateObj;
        commit("UPDATE_INDIVIDUALS", docs);
        const { businesses } = state.paginate;
        commit("UPDATE_PAGINATION", {
          individuals: { page, limit: parseInt(limit), total },
          businesses: {
            page: businesses.page,
            limit: businesses.limit,
            total: businesses.total
          }
        });
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get individual customers",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async details({ commit }, payload) {
    try {
      const { type, id } = payload?.id;
      const response = await Api().get(`/customers/${id}/details?type=${type}`);
      if (response) {
        commit("UPDATE_DETAILS", response.data);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get individual customers",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async listOfCustomers({ commit, dispatch }, payload) {
    try {
      const { branchId } = payload;
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().get(
        `/customers/all/list?branchId=${branchId}`
      );
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        const { businessCustomers, customers } = response.data;
        commit("UPDATE_SEARCH_RESULTS", [...businessCustomers, ...customers]);
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? "Unable to fetch list of customers",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async searchCustomers({ commit, dispatch }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().get(
        `/customers/searches/list?query=${payload?.query}`
      );
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        const { businessCustomers, customers } = response.data;
        commit("UPDATE_SEARCH_RESULTS", [...businessCustomers, ...customers]);
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e.response?.data?.message ?? "Unable to create individual customer",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  }
};
const getters = {
  getIndividualCustomers: state => state.individuals,
  getBusinessCustomers: state => state.businesses,
  getCustomerDetails: state => state.details,
  getPagination: state => state.paginate,
  getCustomerSearchResults: state => state.searchResults
};

export const customers = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
