import { Api } from "../api/Api";

const state = {
  prices: [],
  reset: {
    amount: "",
    productId: ""
  },
  allocationAmount: 0,
  modeOfPayments: []
};

const mutations = {
  UPDATE_PRICES(state, payload) {
    state.prices = payload;
  },
  UPDATE_ALLOCATION_AMOUNT(state, payload) {
    state.allocationAmount = payload;
  },
  UPDATE_MODE_OF_PAYMENT(state, payload) {
    state.modeOfPayments = payload;
  }
};

const actions = {
  async addPrice({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/prices`, payload.body);
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        dispatch("updateDialog", payload.loading, { root: true });
        commit("UPDATE_MESSAGE", "Price added successfully", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        dispatch(
          "products/list",
          { page: 1, itemsPerPage: 10 },
          { root: true }
        );
        dispatch("resetFormState", true, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get list of products", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async listAllocationAmount({ dispatch, commit }, payload) {
    try {
      const response = await Api().get(
        `/shops/amount/allocation?branchId=${payload.branchId}`
      );
      if (response) {
        commit("UPDATE_ALLOCATION_AMOUNT", response.data);
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response.data?.message ?? "Unable to get allocation amount",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async modeOfPaymentList({ commit, dispatch }) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().get(`/sales/mode/of/payments`);
      if (response) {
        commit("UPDATE_MODE_OF_PAYMENT", response.data);
        dispatch("isSubLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            `Unable to fetch list of payment modes or revenue types`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async updateModeOfPayment({ dispatch }, payload) {
    try {
      const { id, ...rest } = payload;
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().put(`/sales/mode/of/payments/${id}`, rest);
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        dispatch("modeOfPaymentList");
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response.data.message.join(","),
          status: "red"
        },
        { root: true }
      );
    }
  }
};

const getters = {
  getAllPrices: state => state.prices,
  getResetFormValues: state => state.reset,
  getAllocationAmount: state => state.allocationAmount,
  getModeOfPaymentList: state => state.modeOfPayments
};

export const prices = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
