import { from, of } from "rxjs";
import { map, toArray } from "rxjs/operators";
import router from "../router/index";
import { Api } from "../api/Api";
const state = {
  users: [],
  details: null
};
const mutations = {
  UPDATE_USERS(state, payload) {
    state.users = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  }
};
const actions = {
  async list({ commit, dispatch }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response$ = from(await (await Api().get(`/user`)).data).pipe(
        map(user => user),
        toArray()
      );
      response$.subscribe(users => {
        dispatch("isListLoading", false, { root: true });
        commit("UPDATE_USERS", users);
      });
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? `Unable to get list of users`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async create({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/user`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: response?.data?.message ?? `User created successfully`,
            status: "success"
          },
          { root: true }
        );
        setTimeout(() => {
          router.push({ name: "master.users" });
        }, 2000);
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? `Unable to create user`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async details({ dispatch, commit }, payload) {
    try {
      const { id } = payload;
      dispatch("isListLoading", false, { root: true });
      const response$ = of((await Api().get(`/user/${id}`)).data).pipe(
        map(({ role, ...rest }) => {
          return {
            ...rest,
            role: role.id
          };
        })
      );
      response$.subscribe(user => {
        dispatch("isListLoading", false, { root: true });
        commit("UPDATE_DETAILS", user);
      });
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? `Unable to create user`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async update({ dispatch }, payload) {
    try {
      const { id } = payload;
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(`/user/${id}`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message:
              response?.data?.message ?? `User details updated successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? `Unable to create user`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async setNotificationPreferences({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id, preferences } = payload;
      const response$ = of(
        (await Api().post(`/user/${id}/notification/preferences`, preferences))
          .data
      );
      response$.subscribe(() => {
        dispatch(
          "updateDialog",
          {
            idx: "generate",
            status: false
          },
          { root: true }
        );
        dispatch("isLoading", false, { root: true });
      });
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? `Unable to create user`,
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getListOfUsers: state => state.users,
  getUserDetails: state => state.details
};

export const users = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
