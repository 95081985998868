import { Api } from "../api/Api";

const state = {
  totals: {
    totalCustomers: 0,
    totalSuppliers: 0,
    totalLubes: 0
  },
  sales: {}
};
const mutations = {
  UPDATE_TOTALS(state, payload) {
    state.totals = payload;
  },
  UPDATE_SALES(state, payload) {
    state.sales = payload;
  }
};
const actions = {
  async totals({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/dashboard/operations/statistics`);
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        const { totalCustomers, totalLubes, totalSuppliers } = response.data;
        commit("UPDATE_TOTALS", { totalCustomers, totalLubes, totalSuppliers });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response?.data?.message ??
            "Unable to fetch stats for the dashboard",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async sales({ commit, dispatch }, payload) {
    try {
      const { startDate, endDate, branchId } = payload;
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().get(
        `/dashboard/operations/sales?startDate=${startDate}&endDate=${endDate}&branchId=${branchId}`
      );
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        commit("UPDATE_SALES", response.data);
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response?.data?.message ??
            "Unable to fetch stats for the dashboard",
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getTotals: state => state.totals,
  getTotalSales: state => state.sales
};

export const dashboard = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
