/* eslint-disable no-unused-vars */
import { Api } from "../api/Api";
import { of } from "rxjs";
import { groupBy, mergeMap } from "rxjs/operators";
import { map } from "rxjs/operators";
const state = {
  products: [],
  lubes: [],
  paginate: {
    total: 0,
    page: 1,
    limit: 5
  }
};

const mutations = {
  UPDATE_PRODUCTS(state, payload) {
    state.products = payload;
  },
  UPDATE_LUBES(state, payload) {
    state.lubes = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  }
};
const actions = {
  async products({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { page, limit, isPaginated } = payload;
      const response = await Api().get(
        `/stocks/for/products?isPaginated=${isPaginated ??
          true}&page=${page}&limit=${limit}`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        if (isPaginated ?? true) {
          const { page, limit, total, docs } = response.data.paginateObj;
          commit("UPDATE_PAGINATION", { page, limit, total });
          commit("UPDATE_PRODUCTS", docs);
        } else {
          commit("UPDATE_PRODUCTS", response.data);
        }
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get fetch stocks for products",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async productsByBranch({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { branchId, page, limit } = payload;
      const response = await Api().get(
        `/stocks/for/products/by/branch/${branchId}?isPaginated=true&page=${page}&limit=${limit}`
      );
      if (response) {
        console.log(response);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get fetch notifications",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async lubeItems({ commit, dispatch }, { isPaginated = true, page, limit }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response$ = of(
        (
          await Api().get(
            `/stocks/available/monthly?isPaginated=${isPaginated}&page=${page}&limit=${limit}`
          )
        ).data
      );
      response$.subscribe(stocks => {
        const { page, total, limit, docs } = stocks.paginateObj;
        commit("UPDATE_PAGINATION", {
          page,
          total,
          limit
        });
        commit("UPDATE_LUBES", docs);
        dispatch("isListLoading", false, { root: true });
      });
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? `Unable to fetch stocks for lubes`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async lubeItemsByBranch({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { page, limit, branches } = payload;
      const response$ = of(
        (
          await Api().get(
            `/stocks/for/lubes/available/by/branch?isPaginated=true&page=${page}&limit=${limit}&branchId=${branches.join(
              ","
            )}`
          )
        ).data
      );
      response$.subscribe(stocks => {
        const { page, total, limit, docs } = stocks.paginateObj;
        commit("UPDATE_PAGINATION", {
          page,
          total,
          limit
        });
        commit("UPDATE_LUBES", docs);
        dispatch("isPageLoading", false, { root: true });
        dispatch("isLoading", false, { root: true });
      });
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            `Unable to filter by the provided branches`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async addOpeningStockForProducts({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { branchId, ...rest } = payload;
      const response$ = of(
        (await Api().post(`/stocks/openings/${branchId}/products`, rest)).data
      );
      response$.subscribe(stocks => {
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Opening stock added successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch("isLoading", false, { root: true });
        dispatch("branches/list", { isPaginated: false }, { root: true });
        dispatch("products", { isPaginated: false });
      });
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ??
            `Unable to add opening stock for products`,
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getAllProductStock: state => state.products,
  getAllLubeStock: state => state.lubes,
  getPagination: state => state.paginate
};

export const stocks = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
