import { Api } from "../api/Api";

const state = {
  drivers: [],
  details: {
    driver: null,
    car: null
  },
  managers: [],
  cars: [],
  paginate: {
    total: 0,
    page: 1,
    limit: 12
  }
};

const mutations = {
  UPDATE_DRIVERS(state, payload) {
    state.drivers = payload;
  },
  UPDATE_CARS(state, payload) {
    state.cars = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_MANAGERS(state, payload) {
    state.managers = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  }
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  async listOfCars({ dispatch, commit }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(`/transports/cars`);
      if (response) {
        dispatch("isListLoading", false, { root: true });
        commit("UPDATE_CARS", response.data);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get list of vehicles", {
        root: true
      });
      commit("UPDATE_STATUS", "success", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async addCar({ dispatch, commit }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().post(`/transports/cars`, payload);
      if (response) {
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "New vehicle added successfully", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        dispatch("isSubLoading", false, { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to add new vehicle", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async listOfDrivers({ dispatch, commit }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/transports/drivers`);
      if (response) {
        commit("UPDATE_DRIVERS", response.data);
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to fetch drivers", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async addDriver({ dispatch, commit }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/transports/drivers`, payload);
      if (response) {
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "New driver added successfully", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        dispatch("isLoading", false, { root: true });
        dispatch("listOfDrivers");
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to add new driver", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async driverDetails({ dispatch, state, commit }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().get(`/transports/drivers/${payload.id}`);
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_DETAILS", {
          car: state.car,
          driver: response.data
        });
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message ?? `Unable to get driver details`,
          status: "red",
          snackbar: true
        },
        { root: true }
      );
    }
  },
  async editDriver({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id, ...rest } = payload;
      const response = await Api().put(`/transports/drivers/${id}`, rest);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("listOfDrivers");
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Driver details updated successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch(
          "updateDialog",
          {
            idx: "edit",
            status: false
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response.data.message ?? `Unable to update driver details`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async unassignUserFromCar({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().delete(
        `/transports/drivers/${payload.id}/unassign/car`
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("listOfDrivers");
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Driver successfully unassigned from truck`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? `Unable to unassign user from truck`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async listTransportManagers({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { page, limit } = payload;
      const response = await Api().get(
        `/user/transport/managers?isPaginated=true&page=${page}&limit=${limit}`
      );
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        const { page, limit, total, docs } = response.data.paginateObj;
        commit("UPDATE_PAGINATION", { page, limit, total });
        commit("UPDATE_MANAGERS", docs);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? `Unable to list transport managers`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async addManager({ dispatch, state }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/user`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "updateDialog",
          {
            idx: "details",
            status: false
          },
          { root: true }
        );
        dispatch("resetFormState", true, { root: true });
        dispatch("listTransportManagers", {
          page: 1,
          limit: state.paginate.limit
        });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Transport manager added successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? `Unable to add transport manager`,
          status: "red"
        },
        { root: true }
      );
    }
  }
};

const getters = {
  getAllDrivers: state => state.drivers,
  getAllCars: state => state.cars,
  getDriverDetails: state => state.details.driver,
  getTransportManagers: state => state.managers,
  getPagination: state => state.paginate
};

export const transport = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
