import { Api } from "../api/Api";
const state = {
  list: [],

  reset: {
    businessRegistrationNumber: "",
    name: "",
    email: "",
    contact: null,
    address: null,
    tin: null,
    officeLocation: null,
    areaOfTradeOrSupply: "",
    businessRegistrationCert: "",
    creditPurchasesAmount: null,
    contactPerson: {
      name: "",
      phone: "",
      email: ""
    }
  },
  paginate: {
    total: 0,
    page: 1,
    limit: 15
  }
};
const mutations = {
  UPDATE_SUPPLIERS(state, payload) {
    state.list = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  }
};
const actions = {
  async list({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/shops/supplier/list?isPaginated=${payload.isPaginated ?? true}&page=${
          payload.page
        }&limit=${payload.limit}${
          payload.filters ? `&filters=${payload.filters}` : ""
        }`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        if (payload.isPaginated) {
          const { page, limit, total, docs } = response.data.paginateObj;
          commit("UPDATE_PAGINATION", {
            page: page,
            limit: limit,
            total: total
          });
          commit("UPDATE_SUPPLIERS", docs);
        } else {
          commit("UPDATE_SUPPLIERS", response.data);
        }
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response.data?.message ?? "unable to get list of suppliers",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async create({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/shops/supplier/create`, payload.body);
      if (response) {
        await Api().post(
          `/shops/supplier/${response.data?.id}/upload`,
          payload.uploads
        );
        dispatch("list", {
          isPaginated: false,
          page: 1,
          limit: 15
        });
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: `Supplier created successfully`,
            status: "success"
          },
          { root: true }
        );

        dispatch("resetFormState", true, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? "Unable to create supplier",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      const { id } = payload?.id;
      const response = await Api().get(`/shops/supplier/${id}`);
      if (response) {
        commit("customers/UPDATE_DETAILS", response.data, { root: true });
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.message ?? "Unable to get individual customers",
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getListOfSuppliers: state => state.list,
  getResetFormValues: state => state.reset,
  getPagination: state => state.paginate
};

export const suppliers = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
