/* eslint-disable no-unused-vars */
import { Api } from "../api/Api";
import { from } from "rxjs";
import { find } from "rxjs/operators";
import router from "../router/index";

const state = {
  branches: [],
  details: null,
  paginate: {
    total: 0,
    limit: 10,
    page: 1
  },
  role: null,
  pumps: []
};
const mutations = {
  UPDATE_BRANCHES(state, payload) {
    state.branches = payload;
  },
  UPDATE_PAGINATE(state, payload) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_ROLE(state, payload) {
    state.role = payload;
  },
  UPDATE_PUMPS(state, payload) {
    state.pumps = payload;
  }
};
const actions = {
  async list({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/branches?paginated=${payload ? payload.isPaginated : false}&page=${
          payload ? payload.page : 1
        }&limit=${payload ? payload.limit : 12}`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        if (response.data.paginateObj) {
          const { docs, page, limit, total } = response.data.paginateObj;
          commit("UPDATE_BRANCHES", docs);
          commit("UPDATE_PAGINATE", { page, limit, total });
        } else {
          commit("UPDATE_BRANCHES", response.data);
          dispatch("isListLoading", false, { root: true });
        }
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? "Unable to fetch branch list",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async create({ state, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/branches`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("resetFormState", true, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: response?.data?.message ?? `Branch created successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch(
          "updateDialog",
          {
            idx: "details",
            status: false
          },
          { root: true }
        );
        dispatch("list", {
          isPaginated: true,
          page: state.paginate.page,
          limit: state.paginate.limit
        });
      }
    } catch (e) {
      dispatch("showSnackBar", {
        snackbar: true,
        message:
          e?.response?.data?.message ?? "Unable to save branch credentials",
        status: "red"
      });
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id } = payload;
      const response = await Api().get(`/branches/${id}`);
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_DETAILS", response.data);
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch("showSnackBar", {
        snackbar: true,
        message:
          e?.response?.data?.message ?? "Unable to save branch credentials",
        status: "red"
      });
    }
  },
  async update({ dispatch, state }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id, ...rest } = payload;
      const response = await Api().put(`/branches/${id}`, rest);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: response?.data?.message ?? `Branch updated successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch(
          "updateDialog",
          {
            idx: "edit",
            status: false
          },
          { root: true }
        );

        dispatch("list", {
          isPaginated: true,
          page: state.paginate.page,
          limit: state.paginate.limit
        });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch("showSnackBar", {
        snackbar: true,
        message:
          e?.response?.data?.message ?? "Unable to save branch credentials",
        status: "red"
      });
    }
  },
  async addBranchAdmin({ commit, dispatch, state }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      if (state.role) {
        const response = await Api().post(`/user`, {
          ...payload,
          role: state.role?.id
        });
        if (response) {
          dispatch("isLoading", false, { root: true });
          dispatch("list", {
            isPaginated: true,
            page: state.paginate.page,
            limit: state.paginate.limit
          });
          dispatch("resetFormState", true, { root: true });
          dispatch(
            "updateDialog",
            {
              idx: "add",
              state: false
            },
            { root: true }
          );
          dispatch(
            "showSnackBar",
            {
              snackbar: true,
              message:
                response?.data?.message ?? "Branch admin added successfully",
              status: "success"
            },
            { root: true }
          );
        }
      } else {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message: "Unable to save branch credentials",
            status: "red"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? "Unable to save branch credentials",
          status: "red"
        },
        { root: true }
      );
    }
  },
  filterBranchDetails({ commit, state }, payload) {
    let details$ = from(state.branches).pipe(
      find(branch => branch.id === payload.id)
    );
    details$.subscribe(details => {
      commit("UPDATE_DETAILS", details);
    });
  },
  async defaultRole({ commit, dispatch, state }, payload) {
    try {
      const response = await Api().get(`/roles/by/user-type/${payload}`);
      if (response) {
        commit("UPDATE_ROLE", response.data);
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? "Unable to get default roles",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async branchPumps({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/pumps/{branchId}/nozzles/meter-readings`.replace(
          "{branchId}",
          payload.branchId
        )
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        commit("UPDATE_PUMPS", response.data);
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.response?.data?.message ?? "Unable to fetch pump list",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async updateMeterReadings({ commit, dispatch, state }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      if (payload.nozzles.length === 0) {
        dispatch(
          "updateDialog",
          {
            idx: "alert",
            state: false
          },
          { root: true }
        );
        throw new Error("No changes was made to be saved");
      }
      const response = await Api().put(
        `/pumps/{branchId}/nozzles/reset/readings`.replace(
          "{branchId}",
          payload.branchId
        ),
        payload
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "updateDialog",
          {
            idx: "alert",
            state: false
          },
          { root: true }
        );
        dispatch(
          "showSnackBar",
          {
            snackbar: true,
            message:
              response?.data?.message ??
              "Opening meter readings has been reset successfully",
            status: "success"
          },
          { root: true }
        );
        router
          .replace({
            name: "master.branches"
          })
          .catch();
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message: e?.message
            ? e?.message
            : e?.response?.data?.message ??
              "Unable to reset branch opening meter reading",
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getAllBranches: state => state.branches,
  getPagination: state => state.paginate,
  getBranchDetails: state => state.details,
  getDefaultBranchRole: state => state.role,
  getAllPumps: state => state.pumps
};
export const branches = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
