import { Api } from "../api/Api";
import { flattenDeep, groupBy } from "lodash";
import {
  OVERALL_SALES_TITLE,
  LUBE_SALES_TITLE,
  DAILY_OPENING_AND_CLOSING_LUBE_SALES_TITLE,
  DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS,
  SHOP_SALES_AND_ALLOCATION_TITLE,
  DAILY_CREDIT_SALES_BY_CUSTOMERS,
  MD_ALLOCATION_ADDED_USERS,
  SHOP_SALES_PURCHASES_AND_PAYMENTS,
  LUBES_GENERAL_MONTHLY_REPORTS,
  FUEL_ORDERS_GENERAL_REPORTS,
  DAILY_EXPENSES_OVER_PERIOD,
  REVENUE_BY_CHANNELS,
  MD_BILLS,
  RTT_LIST,
  findTableHeadingForCustomers,
  MEASURED_STOCK_REPORTS,
  LUBE_ORDERS_TRANSFERS_AND_DAMAGES,
  PUMP_ATTENDEES_WITH_DAILY_SALES
} from "../config/constants";
const state = {
  reports: [],
  titles: []
};

const mutations = {
  UPDATE_REPORTS(state, payload) {
    state.reports = payload;
  },
  UPDATE_TITLE(state, payload) {
    state.titles = payload;
  }
};
const actions = {
  async records({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const {
        startDate,
        endDate,
        branchId,
        action,
        download,
        fileName
      } = payload;

      let response = null;
      const downloadFile = `&download=true&fileName=${fileName}`;
      const query = `?branchId=${branchId}&startDate=${startDate}&endDate=${endDate}&action=${action}${
        payload?.type ? "&type=" + payload.type : ""
      }`;
      switch (action) {
        case "SHOP_SALES":
          commit("UPDATE_TITLE", SHOP_SALES_AND_ALLOCATION_TITLE);
          response = await Api().get(
            `/reports/sales/shops${query}${download ? downloadFile : ""}`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "OVERALL_SALES_LIST":
          commit("UPDATE_TITLE", OVERALL_SALES_TITLE);
          response = await Api().get(
            `/bills-payment/open/review/bills/recorded${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "LUBE_SALES":
          commit("UPDATE_TITLE", LUBE_SALES_TITLE);
          response = await Api().get(
            `/reports/stock/levels/variations/lubes/export${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "DAILY_OPENING_AND_CLOSING_LUBE_SALES":
          commit("UPDATE_TITLE", DAILY_OPENING_AND_CLOSING_LUBE_SALES_TITLE);
          response = await Api().get(
            `/reports/sales/lubes/with/stock/levels${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "DAILY_CREDIT_SALES_BY_CUSTOMERS":
          commit("UPDATE_TITLE", DAILY_CREDIT_SALES_BY_CUSTOMERS);
          response = await Api().get(
            `/reports/credit/sales/for/customers${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS":
          commit("UPDATE_TITLE", DAILY_CREDIT_COLLECTIONS_BY_CUSTOMERS);
          response = await Api().get(
            `/reports/credit/sales/for/customers${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;

        case "MD_ALLOCATION_ADDED_USERS":
          commit("UPDATE_TITLE", MD_ALLOCATION_ADDED_USERS);
          response = await Api().get(
            `/reports/mds/allocations${query}${download ? downloadFile : ""}`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "SHOP_SALES_PURCHASES_AND_PAYMENTS":
          commit("UPDATE_TITLE", SHOP_SALES_PURCHASES_AND_PAYMENTS);
          response = await Api().get(
            `/reports/supplier/shop/sales${query}${
              download ? downloadFile : ""
            }`,
            { responseType: download ? "blob" : "json" }
          );
          break;
        case "LUBES_GENERAL_MONTHLY_REPORTS":
          commit("UPDATE_TITLE", LUBES_GENERAL_MONTHLY_REPORTS);
          response = await Api().get(
            `/reports/lubes/general/monthly/overview${query}${
              download ? downloadFile : ""
            }`,
            { responseType: download ? "blob" : "json" }
          );
          break;
        case "FUEL_ORDERS_GENERAL_REPORTS":
          commit("UPDATE_TITLE", FUEL_ORDERS_GENERAL_REPORTS);
          response = await Api().get(
            `/reports/fuel/orders${query}${download ? downloadFile : ""}`,
            { responseType: download ? "blob" : "json" }
          );
          break;
        case "DAILY_EXPENSES_OVER_PERIOD":
          commit("UPDATE_TITLE", DAILY_EXPENSES_OVER_PERIOD);
          response = await Api().get(
            `/reports/expenses${query}${download ? downloadFile : ""}`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "REVENUE_BY_CHANNELS":
          commit("UPDATE_TITLE", REVENUE_BY_CHANNELS);
          response = await Api().get(
            `/reports/fuel/segregation/revenue/by/channels${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "MD_BILLS":
          commit("UPDATE_TITLE", MD_BILLS);
          response = await Api().get(
            `/reports/mds/bills${query}${download ? downloadFile : ""}`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "CUSTOMERS_LIST":
          commit(
            "UPDATE_TITLE",
            findTableHeadingForCustomers(payload?.customerType)
          );
          response = await Api().get(
            `/reports/customers/all${query}${"&customerType=" +
              payload?.customerType}${download ? downloadFile : ""}`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "RTT_LIST":
          commit("UPDATE_TITLE", RTT_LIST);
          response = await Api().get(
            `/reports/sales/fuels/${branchId}/rtt${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "MEASURED_STOCK_REPORTS":
          commit("UPDATE_TITLE", MEASURED_STOCK_REPORTS);
          response = await Api().get(
            `/reports/sales/${branchId}/stock/variations${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "LUBE_ORDERS_TRANSFERS_AND_DAMAGES":
          commit("UPDATE_TITLE", LUBE_ORDERS_TRANSFERS_AND_DAMAGES);
          response = await Api().get(
            `/reports/lubes/orders/transfers-damages${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "PUMP_ATTENDEES_WITH_DAILY_SALES":
          commit("UPDATE_TITLE", PUMP_ATTENDEES_WITH_DAILY_SALES);
          response = await Api().get(
            `/reports/sales/pump/attendants${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
      }

      if (response) {
        dispatch("isListLoading", false, { root: true });
        if (!download) {
          if (action === "LUBE_SALES") {
            commit(
              "UPDATE_REPORTS",
              Object.entries(groupBy(flattenDeep(response.data), "date"))
            );
          } else commit("UPDATE_REPORTS", response.data);
          return;
        }
        // download the file
        const blob = new Blob([response.data], {
            type: "application/octet-stream"
          }),
          a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get records", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  }
};
const getters = {
  getAllReports: state => state.reports,
  getTitles: state => state.titles
};

export const exportReport = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
