import Vue from "vue";
import VueRouter from "vue-router";
import Blank from "../views/pages/Blank";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/",
    name: "blank",
    component: Blank,
    children: [
      {
        path: "login",
        name: "blank.login",
        component: () => import("../views/auth/Login.vue")
      },
      {
        path: "forgot-password",
        name: "blank.forgot-password",
        component: () => import("../views/auth/ForgotPassword.vue")
      },
      {
        path: "reset",
        name: "blank.reset",
        component: () => import("../views/auth/Reset.vue")
      }
    ]
  },

  {
    path: "/app",
    name: "master",
    component: () => import("../views/layout/Master.vue"),
    children: [
      {
        path: "change/password",
        name: "master.change.password",
        component: () => import("../views/pages/settings/ChangePassword"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "dashboard",
        name: "master.dashboard",
        component: () => import("../views/pages/Dashboard"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "customers",
        name: "master.customers",
        component: () => import("../views/pages/customers/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "branches",
        name: "master.branches",
        component: () => import("../views/pages/branches/Branches.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "branches/meter-readings",
        name: "master.branches.meter-readings",
        component: () =>
          import("../views/pages/branches/ResetPumpMeterReadings.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "branch-managers",
        name: "master.branch-managers",
        component: () => import("../views/pages/branches/BranchManagers.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "branch-details",
        name: "master.branch-details",
        component: () => import("../views/pages/branches/ViewBranch.vue"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "roles",
        name: "master.roles",
        component: () => import("../views/pages/authorization/Roles"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "users",
        name: "master.users",
        component: () => import("../views/pages/authorization/users/Index"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "users/add",
        name: "master.users.add",
        component: () => import("../views/pages/authorization/users/AddUser"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "roles/add",
        name: "master.roles.add",
        component: () => import("../views/pages/authorization/AddRole"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "deliveries",
        name: "master.deliveries",
        component: () => import("../views/pages/deliveries/Deliveries"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "lubes",
        name: "master.lubes",
        component: () => import("../views/pages/lubes/List"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "pending/lubes/orders/by/branch",
        name: "master.lubes.orders.branch",
        component: () => import("../views/pages/lubes/ListLubeOrders"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "approved/lubes/orders/by/branch",
        name: "master.lubes.orders.branch.approved",
        component: () => import("../views/pages/lubes/ListApprovedLubeRequest"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "lubes/:id/order/by/branch",
        name: "master.lubes.order.details",
        component: () => import("../views/pages/lubes/LubeOrderRequestDetails"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "lubes/add/opening/stock",
        name: "master.lubes.opening.stock",
        component: () => import("../views/pages/lubes/AddLubesOpeningStock"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "stock/lubes/list",
        name: "master.stock.list",
        component: () =>
          import("../views/pages/stocks/ListOfStocksPerBranchesForLubes"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "stock/products/list",
        name: "master.stock.products.list",
        component: () =>
          import("../views/pages/stocks/ListOfStocksPerBranchesForProducts"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "sales/products",
        name: "master.sales.products",
        component: () => import("../views/pages/sales/ProductSales"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "sales/lubes",
        name: "master.sales.lubes",
        component: () => import("../views/pages/sales/LubeSales"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "sales/shops",
        name: "master.sales.shops",
        component: () => import("../views/pages/sales/ShopSales"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "sales/pending/approval",
        name: "master.sales.pending",
        component: () => import("../views/pages/approvals/PendingSales"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "sources",
        name: "master.sources",
        component: () => import("../views/pages/management/sources/Sources"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "source-detail",
        name: "master.source-detail",
        component: () =>
          import("../views/pages/management/sources/SourceDetails"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "products",
        name: "master.products",
        component: () => import("../views/pages/management/products/Products"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "products/opening-stock",
        name: "master.products.opening-stock",
        component: () =>
          import("../views/pages/management/products/AddProductsOpeningStock"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "deliveries",
        name: "master.deliveries",
        component: () => import("../views/pages/deliveries/Deliveries"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "product-details",
        name: "master.product-details",
        component: () =>
          import("../views/pages/management/products/ProductsDetails"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "orders",
        name: "master.orders",
        component: () => import("../views/pages/management/orders/Orders"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "order-details",
        name: "master.order-details",
        component: () =>
          import("../views/pages/management/orders/OrderDetails"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/transport",
        name: "master.transport",
        component: () => import("../views/pages/transport/List"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "reports",
        name: "master.reports",
        component: () => import("../views/pages/Reports"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/settings/system",
        name: "master.settings.system",
        component: () => import("../views/pages/settings/SystemSettings"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/reports/overview",
        name: "master.reports.overview",
        component: () => import("../views/reports/Index"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/reports/stock/levels",
        name: "master.stock.levels",
        component: () => import("../views/reports/StocksIndex"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/reports/exports",
        name: "master.reports.exports",
        component: () => import("../views/reports/ExportsAndDownloads"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "dispensers",
        name: "master.dispensers",
        component: () => import("../views/pages/users/Dispensers"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes
});

router.beforeEach((to, from, next) => {
  const isUserLoggedIn = localStorage.getItem("token");
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      localStorage.clear();
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    } else next();
  } else next();
});
export default router;
